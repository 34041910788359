import React, { useMemo, useState } from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import {
  Alert,
  Badge,
  Row,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavLink,
  NavItem,
  FormGroup,
  Input,
  UncontrolledTooltip,
  Button,
  Spinner,
  TabContent,
  TabPane,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faBackward,
  faForward,
  faCalendarAlt,
  faInfoCircle,
  faUnlock,
  faEnvelope,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import DashboardElement from "../../../../utils/DashboardElement";
import DownloadIcon from "@material-ui/icons/CloudDownload";

import "moment/locale/fr-ca";
import "moment/locale/en-ca";
import moment from "moment-timezone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import _ from "lodash";
import axios from "axios";
import { API_DOMAIN } from "../../../../../appConfig";
import { axiosDefaults, getTokenAndId } from "../../../../../appConfigaxios";
import MuiAlert from "@material-ui/lab/Alert";
import Toastr from "../../../../../messages";
import Loader from "../../../../../loader";
import Notes from "./Notes/Notes";
import history from "../../../../../history";

import rootsActions from "../../../../_actions/root-actions";
import Toast from "../../../Toast/Toast";
import { genLang, getLang } from "../../../../../composables/genLang";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { refreshSAASToken } from "../../../../../composables/saasToken";
import { decryptText } from "../../../../../appEncryption";
const QL_CURSOR = /\<span class=["']ql-cursor["']>\s*<\/span>/gm;
const DEFAULT_STYLE = {};
const BACKEND1 = process.env.REACT_APP_API_URLX;
const MACRO_CHAR_LIMIT = 1600;
var toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  // ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    display: "inline-block",
    width: "100%",
    height: "32px",
    minHeight: "32px",
    maxHeight: "32px",
    textAlign: "left",
  }),
  control: (provided) => ({
    ...provided,
    height: "32px",
    minHeight: "32px",
    maxHeight: "32px",
  }),
  input: (provided) => ({
    ...provided,
    minHeight: "5px",
    maxHeight: "5px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
    color: "#757575",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    minHeight: "1px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: "25px",
    fontSize: "10px",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "inline-block",
    fontSize: "15px",
    top: "55%",
  }),
  placeholder: (provided) => ({
    ...provided,
    display: "inline-block",
    position: "absolute",
    fontSize: "15px",
    top: "50%",
  }),
};

//const URL = "https://apidoc.neodesk.app/api/v2";
const URL = API_DOMAIN;

class TimelineLead extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      lang: "fr",
      activeTab: "1",
      loading: false,
      readOnly: false,
      wrongAgent: false,
      macros: [],
      value: {
        id: "ca-01",
        value: "ca-01",
        label: "Note privée",
      },
      notes: [],
      text: "<p></p>",
      raison: "",
      vente: null,
      reasons: [],
      postError: false,
      fetchError: "",
      rappelCreated: false,
      nextLead: "",
      dataAgentsDomains: {},
      agentOptions: [],
      selectedAgent: {},
      isLoaderOpen: false,
      toastrMessage: "",
      toastrType: "",
      toastrDuration: 6000,
      neoZendNotes: [],
      toast: { type: "", message: "" },
    };

    this.saveUkraine = this.saveUkraine.bind(this);
    this.fetchNotes = this.fetchNotes.bind(this);
  }

  handleCreateCalendarNote = (e) => {
    if (
      !["https://stg.neodoc.app", "https://www.neodoc.app"].includes(e.origin)
    )
      return;
    const data = JSON.parse(e?.data);
    const lang = "fr";
    const momentLang = `${lang}-ca`;
    const { start, end, duration, service, users } = data ?? {};
    const formatTime = (time) =>
      moment.utc(time).tz("America/New_York").locale(momentLang).format("LLL");
    const user = users[0] ?? {};
    const strStart = formatTime(start);
    const eventDetails = `<b>Événement calendrier<br> ${service} <br>${strStart}<br>${duration} min avec ${user?.name}`;
    axios
      .post(
        BACKEND1 + "/leads/lead",
        {
          id: this.props.id,
          actionid: "ca-03",
          note: eventDetails,
        },
        {
          params: {},
        }
      )
      .then((status) => {
        let { data } = status.data;
        if (data.notes) {
          this.setState({ notes: [...data.notes], rappelCreated: true });
        }
        this.props.toggleCalendar();
      })
      .catch((e) => {
        console.log(e);
      });
    // }
  };

  componentDidMount() {
    axiosDefaults(null);
    let ticketId = 0;
    const this2 = this;
    if (this.props.ticketId != undefined) {
      ticketId = this.props.ticketId;
    }
    window.addEventListener("message", this.handleCreateCalendarNote);
    this.interval = setInterval(
      this.fetchNotes,
      process.env.FETCH_TIME || 30000
    );
    axios
      .get(BACKEND1 + "/leads", {
        params: {
          id: this.props.id,
          showtrans: 3,
        },
      })
      .then((status) => {
        let { data } = status.data;
        if (data) {
          let neoPeopleList = null;
          if (data.neoPeopleList) {
            neoPeopleList = data.neoPeopleList.map((i) => {
              return { label: i.label, value: i.label, agentId: i.value };
            });
          }
          if (data.lead.status === "3") this.setState({ readOnly: true });
          if (data.lead.id_user !== localStorage.getItem("user_id"))
            this.setState({ wrongAgent: true });
          const reasons = data.macros
            .filter((i) => i.type === "leadReason" && i.title !== "Vente")
            .map((i) => {
              return { label: { en: i.title_en, fr: i.title }, value: i.id };
            });
          if (data.lead && data.lead.extraction) {
            let obj = {
              // ...data.lead.extraction,
              ticket: data.lead.ticket_id,
              leadinfo: { ...data.lead },
              leadHistory: data.leadhistory,
            };

            if (
              data.lead.type_for_lead !== undefined &&
              data.lead.type_for_lead === "suivi"
            ) {
              obj.leadSuivi = {
                type_for_lead: data.lead.type_for_lead,
                ticket: data.lead.ticket_id,
              };
              this.handleGetZendeskNOtes(data.lead.ticket_id);
            }

            if (data.clientinfo !== null) {
              if (data.clientinfo.client) {
                if (!obj.clientEmail && data.clientinfo.client.email)
                  obj.clientEmail = data.clientinfo.client.email;
                if (
                  !obj.fullName &&
                  data.clientinfo.client.firstname &&
                  data.clientinfo.client.lastname
                ) {
                  obj.clientFullName = `${data.clientinfo.client.firstname} ${data.clientinfo.client.lastname}`;
                  obj.firstname = data.clientinfo.client.firstname ?? "";
                  obj.lastname = data.clientinfo.client.lastname ?? "";
                }
                if (!obj.clientPhone && data.clientinfo.client.cell)
                  obj.clientPhone = data.clientinfo.client.cell;
              }
              obj.type = "client";
              obj.transactions = data.clientinfo.transactions;
              obj.clientId = data.clientinfo.client_id;
              obj.zendesk_id = data.clientinfo.client.id_zendesk;
              if (data.clientinfo.address) {
                let str = "";
                try {
                  str += data.clientinfo.address.address;
                } catch {
                  str += "";
                }
                try {
                  str += `, ${data.clientinfo.address.city}`;
                } catch {
                  str += "";
                }
                try {
                  str += `, ${data.clientinfo.address.province}`;
                } catch {
                  str += "";
                }
                obj.clientAddress = str;
              }
            } else if (data.clientinfo === null && data.contactinfo !== null) {
              obj.type = "contact";
              obj = { ...obj, ...data.contactinfo };
            } else if (!data.clientinfo) obj.transactions = [];
            else {
              if (data.lead.extraction) {
                obj.type = "client";
                obj.clientFullName = data.lead.extraction.clientFullName;
                obj.clientEmail = data.lead.extraction.clientEmail;
                obj.clientPhone = data.lead.extraction.clientPhone;
              }
            }
            this.props.setCustomer(obj);
          }
          this.genLead();
          this.setState({
            ...data,
            reasons: reasons,
            neoPeopleList: neoPeopleList,
            // ...JSON.parse(data.definition)
          });
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          this.setState({ fetchError: error.response.data });
        }
      });
    axios
      .get(BACKEND1 + "/leads", {
        params: {
          agentTransfer: "yes",
        },
      })
      .then((status) => {
        let { data } = status.data;
        //const dataAll= this.state.dataRawList;
        if (data && data.agentList) {
          let agentList = data.agentList;
          const lead = this.state.lead;
          if (lead && lead.type_for_lead === "suivi" && data.agentList2) {
            agentList = data.agentList2;
          }
          const agentOptions = agentList.map((i) => {
            return { label: i.label, value: i.label, agentId: i.value };
          });
          // agentOptions.unshift({label: 'Aucun Agent/Enlever Agent', value: null, name: null, agentId: null})
          agentOptions.push({
            label: "Aucun/None",
            value: "Aucun/None",
            agentId: "",
          });
          this.setState({
            dataAgentsDomains: data,
            agentOptions: agentOptions,
          });
          this.setState({ ["isLoaderOpen"]: false });
          //thermo:e.thermo,
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log("error object:", error.response.data);
          this2.setState({ postErrorMsg: error.response.data });
        }
      });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener("message", this.handleCreateCalendarNote);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  async fetchNotes() {
    const { data } = await axios.get(BACKEND1 + "/leads", {
      params: {
        //src: "desk",
        id: this.props.id,
        leadreadonly: "3",
      },
    });
    const is_same = this.state.notes.length === data.data.notes.length;
    if (is_same !== true) {
      this.setState({ notes: data.data.notes });
    }
  }

  getLeadProducts = async () => {
    const leadSubject = this.state.lead?.definition?.ticket_subject ?? "";
    if (leadSubject) {
      try {
        const { data } = await axios.get(BACKEND1 + "/campaignlist", {
          params: {
            limit: 4,
            campaign_name: leadSubject,
            is_exact: true
          },
        });
        return data?.data ?? [];
      } catch (error) {
        console.error(error);
      }
    }
  };

  handleGetZendeskNOtes = (taId) => {
    axios
      .get(
        "https://neobackendstaging-kagyonahja-nn.a.run.app/GetFilterBy/notesByFolderId/" +
          taId,
        {
          params: {
            //id: this.props.id,
            showtrans: 3,
          },
        }
      )
      .then((status) => {
        let { data } = status.data;
        if (data) {
          let neoPeopleList = null;
          const neoZendNotes = [];
          if (data.list && data.list.notes) {
            for (let note of data.list.notes) {
              if (note.id_zendesk && note.id_zendesk > 0) {
                neoZendNotes.push(note);
              }
            }
            if (neoZendNotes.length > 0) {
              this.setState({
                neoZendNotes: neoZendNotes,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  saveUkraine(e) {
    let { value, text, raison, vente, postError } = this.state;
    if (text.length > MACRO_CHAR_LIMIT && value?.notify?.notifySms) {
      toast.error(
        genLang({
          en: `SMS cannot be longer than ${MACRO_CHAR_LIMIT} characters`,
          fr: `Le SMS ne peut pas dépasser ${MACRO_CHAR_LIMIT} caractères`,
        })
      );
      return;
    }
    const obj = { id: this.props.id, massActions: [] };
    if (
      value.value !== undefined &&
      text !== "<p></p>" &&
      text !== "<p><br></p>"
    )
      obj.massActions.push({ actionid: value.value, note: text });
    if (vente === true)
      obj.massActions.push({ actionid: "lr-0", note: "Vente/Sale" });
    if (vente === false && raison !== "")
      obj.massActions.push({ actionid: raison, note: "" });
    if (obj.massActions.length === 0) {
      this.setState({
        postError: true,
        loading: false,
      });
      postError = true;
    } else if (obj.massActions.length > 0) {
      this.setState({
        postError: false,
        loading: true,
      });
      postError = false;
    }
    if (!postError && obj.massActions.length) {
      axios
        .post(BACKEND1 + "/leads/lead", obj, {
          params: {},
        })
        .then((status) => {
          let { data } = status.data;
          if (data.notes) {
            this.setState({ notes: [...data.notes] });
          }
          if (vente === true) {
            setTimeout(async () => {
              const leadProductSuggestions = await this.getLeadProducts();
              this.props.history.push(`/neoventes/${this.props.id}/leadId`, {
                leadProductSuggestions
              });
            }, 1000);
          }
          this.setState({
            text: "<p></p>",
            value: {
              id: "ca-01",
              value: "ca-01",
              label: "Note privée",
            },
            vente: null,
            raison: "",
            postError: false,
            loading: false,
          });
        })
        .catch((e) => {
          this.setState({
            toast: { type: "error", message: this.genLang("postError") },
          });
          console.log(e);
        });
    }
  }

  onChange(e) {
    this.setState({
      value: e,
      text: e.html,
    });
  }

  handleUnlockLead = () => {
    const userId = localStorage.getItem("user_id");
    axios
      .post(
        BACKEND1 + "/leads/leads/lead",
        {
          id: this.props.id,
          status: "2",
          id_user: userId,
        },
        {
          params: {},
        }
      )
      .then((res) => {
        const { data } = res.data;
        if (data) {
          const obj = {
            ...this.props.customer,
            leadinfo: {
              ...this.props.customer.leadinfo,
              status: "2",
              id_user: userId,
            },
          };
          this.setState(
            (prevState) => {
              return {
                ...prevState,
                lead: { ...prevState.lead, status: "2", id_user: userId },
                readOnly: false,
                wrongAgent: false,
              };
            },
            () => this.props.setCustomer(obj)
          );
        }
      });
  };

  handleUnlockLeadNote = (note) => {
    let str;
    const agent = this.state.agentOptions.find(
      (i) => i.agentId === localStorage.getItem("user_id")
    );
    if (note === "open") {
      str = `Lead ouvrir et pris en charge par/ Lead opened and taken charge by: ${agent?.label} Agent ID: ${agent?.agentId}`;
    } else if (note === "agent") {
      str = `Lead transféré et pris en charge par/ Lead transfered and taken charge by: ${agent?.label} Agent ID: ${agent?.agentId}`;
    }
    axios
      .post(
        BACKEND1 + "/leads/lead",
        {
          id: this.props.id,
          actionid: "ca-01",
          note: str,
        },
        { params: {} }
      )
      .then((status) => {
        let { data } = status.data;
        if (data.notes) {
          this.setState({ notes: [...data.notes] });
        }
      });
  };

  genLead = () => {
    const leads = JSON.parse(sessionStorage.getItem("leads"));
    if (leads && leads.length) {
      const curIdx = _.findIndex(leads, (i) => i === this.props.id) + 1;
      if (curIdx < leads.length) {
        this.setState({ nextLead: leads[curIdx] });
      } else if (curIdx === leads.length) {
        this.setState({ nextLead: leads[0] });
      }
    }
  };

  genLang = (text) => {
    const { trad, lang } = this.props;
    return trad["neoleads"][text][lang];
  };

  urlify = (text) => {
    var urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url + "</a>";
    });
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  };
  handleSelectChange = (e, field) => {
    this.setState({ ["" + field]: e });
  };

  showToast = (arg, toastType) => {
    //toastType: success or error or warning or info
    const toastrDuration = this.state.toastrDuration; //5500
    this.setState({
      toastrMessage: arg,
      toastrType: toastType,
    });
    setTimeout(() => {
      this.setState({
        toastrMessage: "",
        toastrType: "success",
      });
    }, toastrDuration);
  };

  Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  transferLeadByAgent = (event) => {
    const selectedAgent = this.state.selectedAgent;
    if (
      selectedAgent.agentId !== undefined &&
      parseInt(selectedAgent.agentId) > 0
    ) {
    } else {
      this.showToast(
        "Veuiller de choisir un agent/Please choose an agent.",
        "error"
      );
      return false;
    }

    this.setState({ ["isLoaderOpen"]: true });
    const this2 = this;

    const agentOptions = this.state.agentOptions;
    let agentName = "" + selectedAgent.value;

    const rawdata = { agentID: selectedAgent.agentId };
    const str =
      "Lead transféré à/Lead transfered to: " +
      agentName +
      " #" +
      selectedAgent.agentId;

    axios
      .post(BACKEND1 + "/leads/lead", {
        id: this.props.id,
        actionid: "op-02",
        note: str,
        rawdata: rawdata,
      })
      .then((status) => {
        let { data } = status.data;
        this.setState({
          ["isLoaderOpen"]: false,
          ["selectedAgent"]: {
            label: "Aucun/None",
            value: "Aucun/None",
            agentId: "",
          },
        });
        this.fetchNotes();
      })
      .catch(function (error) {
        this2.setState({ ["isLoaderOpen"]: false });
        window.alert(error);
      });
  };

  genDownloadURL = (url, filename, bucket) => {
    const tokobj = getTokenAndId();
    const token = tokobj.token;
    const userid = tokobj.userid;
    return (
      URL +
      "/files/getfile?url=" +
      url +
      "&token=" +
      token +
      //localStorage.getItem("token") +
      "&action=download" +
      "&filename=" +
      filename +
      "&bucket=" +
      bucket +
      "&auth_id=" +
      userid
      //AUTH_ID.replace(/\s+/g, "")
    );
  };

  render() {
    let { props } = this;
    let macros = this.state.macros.filter((i) => i.type !== "leadReason");
    let notesx = this.state.notes || [];
    const lead = this.state.lead;
    const neoZendNotes = this.state.neoZendNotes;
    const attachmentsDocs = [];
    let notes = [];

    if (neoZendNotes && neoZendNotes.length > 0) {
      notes = notesx.concat(neoZendNotes);

      notes.sort(function (a, b) {
        const date1 = moment(a.date_created);
        const date2 = moment(b.date_created);
        return date1 < date2 ? 1 : date1 > date2 ? -1 : 0;
      });

      for (let noteObj of notes) {
        if (
          noteObj &&
          noteObj.attachments !== undefined &&
          noteObj.attachments.length > 0
        ) {
          //keyID=noteObj.id;
          for (let attachmentsx of noteObj.attachments) {
            const file_name = attachmentsx.file_name;
            const content_url = attachmentsx.content_url;
            if (file_name != "" && content_url !== null && content_url !== "") {
              attachmentsDocs.push(
                <span>
                  {file_name}{" "}
                  <a target="_blank" href={content_url}>
                    <DownloadIcon style={{ fontSize: 20 }} />
                  </a>{" "}
                  &nbsp;{" "}
                </span>
              );
            } else if (attachmentsx.value != "" && attachmentsx.bucket != "") {
              const downloadURL = this.genDownloadURL(
                attachmentsx.value,
                attachmentsx.file_name,
                attachmentsx.bucket
              );
              attachmentsDocs.push(
                <span>
                  {file_name}{" "}
                  <a target="_blank" href={downloadURL}>
                    <DownloadIcon style={{ fontSize: 20 }} />
                  </a>{" "}
                  &nbsp;{" "}
                </span>
              );
            }
          }
        }
      }
    } else {
      notes = notesx;
      if (
        this.props.customer.leadSuivi &&
        this.props.customer.leadSuivi.type_for_lead === "suivi"
      ) {
        for (let noteObj of notes) {
          if (
            noteObj &&
            noteObj.attachments !== undefined &&
            noteObj.attachments.length > 0
          ) {
            for (let attachmentsx of noteObj.attachments) {
              const file_name = attachmentsx.file_name;
              const content_url = attachmentsx.content_url;
              if (
                file_name != "" &&
                content_url !== null &&
                content_url !== ""
              ) {
                attachmentsDocs.push(
                  <span>
                    {file_name}{" "}
                    <a target="_blank" href={content_url}>
                      <DownloadIcon style={{ fontSize: 20 }} />
                    </a>{" "}
                    &nbsp;{" "}
                  </span>
                );
              } else if (
                attachmentsx.value != "" &&
                attachmentsx.bucket != ""
              ) {
                const downloadURL = this.genDownloadURL(
                  attachmentsx.value,
                  attachmentsx.file_name,
                  attachmentsx.bucket
                );
                attachmentsDocs.push(
                  <span>
                    {file_name}{" "}
                    <a target="_blank" href={downloadURL}>
                      <DownloadIcon style={{ fontSize: 20 }} />
                    </a>{" "}
                    &nbsp;{" "}
                  </span>
                );
              }
            }
          }
        }
      }
    }

    const { Option, SingleValue } = components;
    const IconOption = (props) => (
      <Option {...props}>
        {props.data?.notify?.notifySms && (
          <FontAwesomeIcon
            icon={faMobileAlt}
            style={{ color: "#0076CF", marginRight: "0.5rem" }}
          />
        )}
        {props.data?.notify?.notifyEmail && (
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{ color: "#0076CF", marginRight: "0.5rem" }}
          />
        )}
        {props.data.label}
      </Option>
    );

    const SingleValueOption = (props) => (
      <SingleValue {...props}>
        {props.data?.notify?.notifySms && (
          <FontAwesomeIcon
            icon={faMobileAlt}
            style={{ color: "#0076CF", marginRight: "0.5rem" }}
          />
        )}
        {props.data?.notify?.notifyEmail && (
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{ color: "#0076CF", marginRight: "0.5rem" }}
          />
        )}
        {props.data.label}
      </SingleValue>
    );

    const agentOptionsx2 = this.state.agentOptions;
    const selectedAgent = this.state.selectedAgent;
    const neoPeopleList = this.state.neoPeopleList;
    let agentOptions = {};
    if (neoPeopleList && neoPeopleList.length > 0) {
      agentOptions = neoPeopleList;
    } else {
      agentOptions = agentOptionsx2;
    }
    const handleSelectChange = this.handleSelectChange;
    const myToastMessage = this.state.toastrMessage;
    const toastrType = this.state.toastrType;
    const ticket_id = 1066062;

    if (this.state.redirect) {
      return <Redirect to={{ pathname: this.state.redirect }} />;
    }
    if (props.customer && props.customer.conflict) {
      if (props.customer.conflict === "conflict") {
        macros = _.filter(macros, { type: "conflit" });
      }
    }

    let clientIdForBooking = "0";
    if (
      this.props.customer.type === "client" &&
      this.props?.customer?.clientId
    ) {
      clientIdForBooking = this.props?.customer?.clientId;
    }
    // else if (this.props.customer.type === "contact" && this.props?.customer?.id_clients) {
    //   clientIdForBooking = this.props?.customer?.id_clients
    // }

    return (
      <>
        <Row>
          <Toast
            type={this.state.toast.type}
            message={this.state.toast.message}
            clear={() => this.setState({ toast: { type: "", message: "" } })}
            autoHide
          />
          {/* <Link onClick={() => {history.push(`/neoventes/${this.props.id}/leadId`)}}>TEST</Link> */}
          <Col md={3}>
            <Link to={"/leads"}>
              <Button
                outline
                color="primary"
                style={{
                  whiteSpace: "nowrap",
                  marginTop: "2.5rem",
                  marginBottom: "2rem",
                  width: "185px",
                  height: "32px",
                }}
                onClick={() => {}}
              >
                <FontAwesomeIcon icon={faBackward} />
                {" " + this.genLang("timelineLeadReturn")}
              </Button>
            </Link>
          </Col>
          <Col md={6}>
            <h1
              onClick={() => console.log(this.state)}
              style={{
                color: "#0052BB",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
              className="text-align-center element-header"
            >
              Timeline lead
            </h1>
          </Col>
          <Col md={3}>
            {!this.state.readOnly && !this.state.wrongAgent ? (
              <a href={`/leads/${this.state.nextLead}`}>
                <Button
                  outline
                  color="primary"
                  disabled={!this.state.nextLead}
                  style={
                    !this.state.nextLead
                      ? {
                          cursor: "not-allowed",
                          whiteSpace: "nowrap",
                          float: "right",
                          marginTop: "2.5rem",
                          marginBottom: "2rem",
                          width: "185px",
                          height: "32px",
                        }
                      : {
                          whiteSpace: "nowrap",
                          float: "right",
                          marginTop: "2.5rem",
                          marginBottom: "2rem",
                          width: "185px",
                          height: "32px",
                        }
                  }
                >
                  {`${this.genLang("timelineLeadNextLead")} `}
                  <FontAwesomeIcon icon={faForward} />
                </Button>
              </a>
            ) : (
              <Button
                outline
                color="primary"
                onClick={() => {
                  this.handleUnlockLead();
                  if (this.state.readOnly) {
                    this.handleUnlockLeadNote("open");
                  } else if (this.state.wrongAgent) {
                    this.handleUnlockLeadNote("agent");
                  }
                }}
                style={{
                  whiteSpace: "nowrap",
                  float: "right",
                  marginTop: "2.5rem",
                  marginBottom: "2rem",
                  width: "185px",
                  height: "32px",
                }}
              >
                {`${this.genLang("takeLead")} `}
                <FontAwesomeIcon icon={faUnlock} />
              </Button>
            )}
          </Col>
        </Row>
        {props.customer.conflict === "conflict" ? (
          <Row>
            <Col>
              <Button
                type={"button"}
                onClick={(e) => {
                  this.props.setConflict("");
                }}
              >
                Réinitialiser les conflits
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {this.state.postError && (
          <Alert
            color="danger"
            toggle={() => this.setState({ postError: false })}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            {`${this.genLang("fillOutAllFields")}`}
          </Alert>
        )}
        {this.state.fetchError && (
          <Alert color="danger">
            <FontAwesomeIcon icon={faInfoCircle} />
            {` ${this.state.fetchError}`}
          </Alert>
        )}
        {this.state.rappelCreated && (
          <Alert
            color="success"
            toggle={() => this.setState({ rappelCreated: false })}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            {` ${this.genLang("rappelCreated")}`}
          </Alert>
        )}
        {this.state.readOnly && (
          <Alert color="primary">
            <FontAwesomeIcon icon={faInfoCircle} />
            {` ${this.genLang("leadClosedMessage")}`}
          </Alert>
        )}
        {this.state.wrongAgent && (
          <Alert color="primary">
            <FontAwesomeIcon icon={faInfoCircle} />
            {` ${this.genLang("leadNotAssigned")}`}
          </Alert>
        )}
        <Row>
          <Col md={12} className="m-b-10">
            {!this.state.readOnly && !this.state.wrongAgent && (
              <FormGroup>
                <Select
                  id={"macroList"}
                  tabIndex={props.tabindex}
                  onChange={(e) => this.onChange(e)}
                  options={_.map(macros, (e) => {
                    return { ...e, value: e.id, label: e.title, html: e.value };
                  })}
                  value={this.state.value || []}
                  name={"macroList"}
                  key={"macroList"}
                  isDisabled={this.state.loading}
                  isSearchable
                  placeholder={this.genLang("search")}
                  components={{
                    Option: IconOption,
                    SingleValue: SingleValueOption,
                  }}
                />
              </FormGroup>
            )}
          </Col>
        </Row>

        {!this.state.readOnly && !this.state.wrongAgent && (
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <ReactQuill
                    className="contenteditable"
                    readOnly={
                      this.props.disabled ||
                      this.state.loading ||
                      this.state.value.length === 0
                    }
                    style={
                      this.state.loading || this.state.value.length === 0
                        ? { cursor: "not-allowed" }
                        : {}
                    }
                    name={"notre_dame_des_eclaireurs"}
                    theme="snow"
                    modules={{ toolbar: toolbarOptions }}
                    value={this.state.text || ""}
                    onChange={(e, a, b, c) => {
                      let clean = e.replace(QL_CURSOR, "");
                      this.setState({ text: clean });
                    }}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        )}
        {!this.state.readOnly && !this.state.wrongAgent && (
          <Row md={12} style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Col md={6}>
              <Button
                outline={this.state.vente !== true}
                // size="sm"
                color="primary"
                disabled={this.state.loading}
                style={
                  this.state.loading
                    ? {
                        marginRight: "0.3rem",
                        whiteSpace: "nowrap",
                        width: "32%",
                        cursor: "not-allowed",
                        height: "100%",
                      }
                    : {
                        marginRight: "0.3rem",
                        whiteSpace: "nowrap",
                        width: "32%",
                        height: "100%",
                      }
                }
                onClick={() => {
                  const vente = this.state.vente === true ? null : true;
                  this.setState({
                    vente: vente,
                  });
                }}
              >
                {`${this.genLang("sale")}`} <FontAwesomeIcon icon={faCheck} />
              </Button>

              <Button
                outline={this.state.vente !== false}
                // size="sm"
                disabled={this.state.loading === true}
                color="primary"
                style={
                  this.state.loading
                    ? {
                        marginRight: "0.3rem",
                        whiteSpace: "nowrap",
                        width: "32%",
                        cursor: "not-allowed",
                        height: "100%",
                      }
                    : {
                        marginRight: "0.3rem",
                        whiteSpace: "nowrap",
                        width: "32%",
                        height: "100%",
                      }
                }
                onClick={(e) => {
                  const vente = this.state.vente === false ? null : false;
                  this.setState({
                    vente: vente,
                  });
                }}
              >
                {`${this.genLang("noSale")} `}{" "}
                <FontAwesomeIcon icon={faTimes} />
              </Button>
              <BookingModal
                setReadOnly={() => this.setState({ readOnly: true })}
                vente={this.state.vente}
                loading={this.state.loading}
                customer={this.props.customer}
                leadId={this.props.id}
              />
            </Col>

            <Col md={3}>
              <Select
                options={this.state.reasons.map((i) => {
                  return { ...i, label: i.label[this.props.lang] };
                })}
                styles={customStyles}
                isDisabled={
                  this.state.vente === true ||
                  this.state.vente === null ||
                  this.state.loading === true
                }
                onChange={(e) => this.setState({ raison: e.value })}
              />
            </Col>

            <Col md={3}>
              <Button
                outline
                style={
                  this.state.loading
                    ? { cursor: "not-allowed", height: "100%" }
                    : { height: "100%" }
                }
                disabled={this.state.loading}
                color="primary"
                // size="sm"
                block={true}
                onClick={this.saveUkraine}
                type="button"
              >
                {this.state.loading ? (
                  <div>
                    {`${this.genLang("loading")}`}{" "}
                    <Spinner color="primary" size="sm" />
                  </div>
                ) : (
                  `${this.genLang("register")}`
                )}
              </Button>
            </Col>
          </Row>
        )}

        {!this.state.readOnly && !this.state.wrongAgent && (
          <Row style={{ marginBottom: "15px" }}>
            <Col md={4}>
              <Label>{this.genLang("transferLead")}</Label>
              <Select
                options={agentOptions}
                styles={customStyles}
                placeholder={"Choisir Agent/Select an agent"}
                value={agentOptions.find(
                  (i) => i.agentId === selectedAgent.agentId
                )}
                onChange={(e) => {
                  handleSelectChange(e, "selectedAgent");
                }}
              />
            </Col>
            <Col md="3">
              <Button
                outline
                //style={this.state.loading ? {cursor: 'not-allowed'} : {}}
                disabled={this.state.loading}
                color="primary"
                style={{ height: "32px", marginTop: "1.6rem" }}
                // size="sm"
                block={true}
                onClick={this.transferLeadByAgent}
                type="button"
              >
                {this.genLang("transferLeadButton")}
              </Button>
            </Col>
          </Row>
        )}

        {this.props.customer.type === "client" &&
          this.props.customer.leadSuivi &&
          this.props.customer.leadSuivi.type_for_lead === "suivi" &&
          attachmentsDocs.length > 0 && (
            <DashboardElement
              // classNameBody="p-0"
              className="p-0"
              col={12}
              height={"auto"}
              maxHeight={"auto"}
              title={"RÉSUMÉ DE FICHIERS"}
              toggle={true}
              open={false}
            >
              <>
                {attachmentsDocs.length > 0 ? (
                  <div className="messageArchivexX" key={"ooooooookcccccccc"}>
                    {attachmentsDocs}
                  </div>
                ) : (
                  ""
                )}
              </>
            </DashboardElement>
          )}

        <Notes notes={notes} />
        <Loader openx={this.state.isLoaderOpen} />
        <Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  rootsActions: rootsActions,
  loading: state.loading.loading,
  request: state.request,
  customer: state.customer,
});

const mapActionsToProps = {
  setCustomer: rootsActions.customersActions.setUser,
  setConflict: rootsActions.customersActions.setConflict,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(TimelineLead));

export function BookingModal({ customer, leadId, vente, loading, setReadOnly }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [AMPMLoading, setAMPMLoading] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  function genStartTime() {
    const now = moment().tz("America/New_York");
    let startTime;

    if (now.hour() < 12) {
      startTime = now.clone().set({
        hour: 12, // 12 PM
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    } else {
      startTime = now.clone().add(1, "day").set({
        hour: 0, // 12 AM
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }
    const endTime = startTime.clone().add(15, "minutes");

    const obj = {
      start: startTime.format("YYYY-MM-DDTHH:mm:ssZ"),
      end: endTime.format("YYYY-MM-DDTHH:mm:ssZ"),
    };
    return obj;
  }

  function createAutoBookingNote(start) {
    const lang = getLang();
    const momentLang = `${lang}-ca`;
    const formatTime = (time) =>
      moment.utc(time).tz("America/New_York").locale(momentLang).format("LLL");
    const strStart = formatTime(start);
    const user = decryptText(localStorage.getItem("user")).split(":");
    const eventDetails = `<b>${genLang({
      en: "Calendar event",
      fr: "Événement calendrier",
    })}<br> 
      ${genLang({ en: "Follow up", fr: "Suivi" })} 
      <br>${strStart}<br> 15min ${genLang({ en: "with", fr: "Avec" })} 
      ${user[2]}`;
    axios
      .post(
        BACKEND1 + "/leads/lead",
        {
          id: leadId,
          actionid: "ca-03",
          note: eventDetails,
        },
        {
          params: {},
        }
      )
      .then(() => {
        setReadOnly();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleAutoBookingPost(retried = false) {
    setAMPMLoading(true);
    const obj = genStartTime();
    const { start, end } = obj;
    const jwtSAASToken = localStorage.getItem("saas_token");
    const decoded = jwtDecode(jwtSAASToken);
    const clientObj = genClientObj();
    const calendarBaseURL = process.env.REACT_APP_SAAS_API_URL;
    axios
      .post(
        calendarBaseURL + "/calendar/v1/appointment/book",
        {
          timezone: "America/Toronto",
          metadata: { client: navigator.userAgent },
          users: [{ id: decoded.sub }],
          service_id: "17",
          category: 1,
          service: "Suivi",
          type: 1,
          title: "Suivi",
          description: "",
          where: "Montréal",
          start,
          end,
          duration: 15,
          multi: true,
          ticket_id: leadId,
          clients: [clientObj],
        },
        {
          params: { auth_id: decoded.sub },
          headers: {
            Authorization: `Bearer ${jwtSAASToken}`,
          },
        }
      )
      .then((res) => {
        setIsOpen(false);
        setShowCalendar(false);
        toast.success("Rappel auto créé");
        createAutoBookingNote(start);
      })
      .catch(async (e) => {
        if (retried === false) {
          await refreshSAASToken(); //refresh saas token
          return handleAutoBookingPost(true);
        }
        toast.error("Erreur lors de la création du rappel");
        console.log(e);
      })
      .finally(() => {
        setAMPMLoading(false);
      });
  }

  function genClientObj() {
    return {
      firstname: customer?.firstname?.trim() || "",
      lastname: customer?.lastname?.trim() || "",
      email: customer?.clientEmail?.trim() || "",
      id: (customer?.type === "client" && customer?.clientId) || "0",
    };
  }

  const loginURL = useMemo(() => {
    const clientObj = genClientObj();
    const calendarBaseURL =
      process.env.REACT_APP_ENV === "production"
        ? "https://neodoc.app"
        : "https://stg.neodoc.app";
    const url = axios.getUri({
      url: `/calendar/book/${leadId}`,
      params: {
        groups: [],
        domain_id: undefined, //product id
        service_id: 17, //step id
        clients: JSON.stringify([clientObj]),
        lang: localStorage.getItem("i18nextLng")?.split("-")[0] ?? "fr",
        external_user_id: customer?.leadinfo?.id_user ?? "",
      },
    });
    return axios.getUri({
      url: `${calendarBaseURL}/login-psj/${localStorage.getItem(
        "admin_token"
      )}`,
      params: {
        redirect: url,
      },
    });
  }, [customer]);

  return (
    <>
      <Button
        onClick={toggle}
        color="primary"
        outline
        style={{ width: "32%", height: "100%" }}
        disabled={loading || vente}
      >
        <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: "5px" }} />
        {genLang({ en: "Reminder", fr: "Rappel" })}
      </Button>
      <Modal
        isOpen={isOpen}
        centered
        size={showCalendar && "xl"}
        onClosed={() => setShowCalendar(false)}
      >
        <ModalHeader toggle={toggle}>
          {genLang({ en: "Calendar", fr: "Calendrier" })}
        </ModalHeader>
        {showCalendar ? (
          <ModalBody style={{ height: "78vh" }}>
            <iframe
              allow="clipboard-read; clipboard-write"
              key={loginURL}
              className="calendar"
              scrolling="yes"
              src={loginURL}
              height={"100%"}
              width={"100%"}
              style={{ border: "none", borderRadius: "7px" }}
            />
          </ModalBody>
        ) : (
          <ModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <Button
                color="primary"
                style={{ width: "50%" }}
                onClick={() => handleAutoBookingPost()}
              >
                {AMPMLoading ? <Spinner size="sm" /> : "AM/PM"}
              </Button>
              <Button
                color="primary"
                style={{ width: "50%", height: "33px" }}
                onClick={() => setShowCalendar(true)}
              >
                {genLang({ en: "Fixed time", fr: "Heure fixe" })}
              </Button>
            </div>
          </ModalBody>
        )}
      </Modal>
    </>
  );
}
